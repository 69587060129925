import React from 'react';
import { Link } from "gatsby";
import styled from "styled-components";

import SipkaButton from "../assets/img/sipkaButton.png";


const RozcestnikButtonInner = styled.div`
  background: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  border-radius: 5px;
  text-align: center;
  padding: 20px 25px;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 320px;
  width: calc(100% - 50px - 40px);

  a {
    text-decoration: none;

    h2 {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 18px;
      color: #000000;
      vertical-align: middle;
      margin-bottom: 15px;

      img {
        height: 11px;
        vertical-align: inital;
        margin-left: 10px;
      }
    }

    p {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 16px;
      color: #777777;
      line-height: 21px;
    }
  }
`;

const RozcestnikButton = (props) => {
  let text = {};

  if (props.zavete) {
    text = {
      url: "/zaveti",
      nadpis: "Vytvořit závěť",
      text: "Vytvořte si závěť do 15 minut, bez notáře, z pohodlí vašeho domova."
    }
  }

  if (props.poradna) {
    text = {
      url: "/poradna",
      nadpis: "Poradna",
      text: "Desítky článků, ve kterých vám poradíme se vším, co se smrti týče."
    }
  }

  if (props.srovnavac) {
    text = {
      url: "/pohrebni-sluzby",
      nadpis: "Vybrat pohřební službu",
      text: "Transparentní přehled všech pohřebních služeb v Čechách."
    }
  }

  return (
    <RozcestnikButtonInner>
      <Link to={text.url}>
        <h2>{text.nadpis}<img src={SipkaButton} alt={text.nadpis}/></h2>
        <p>{text.text}</p>
      </Link>
    </RozcestnikButtonInner>
  );
}

export default RozcestnikButton;
